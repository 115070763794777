import React, { useState, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import DynamicZone from '../helpers/dynamicZone'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/carousel'
import { Button } from '../components/button'
import ModalButtonAdmission from '../components/modalButtonAdmission'
import Icon from '../components/icon'
import '../css/_uccCareer.scss'

const CareerPage = (props) => {
  const { pageContext, data } = props
  const { data: page } = pageContext
  const { strapiUccCareer: career } = data
  const { components } = career

  // Variable para controlar la apertura o cierre del modal
  const [modalButton, setModalButton] = useState(false);

  // Validacion para recorrer as imagenes en el modal abierto
  const openModal = () => {
    setModalButton(true);
  };
  
  // Cierre del modal
  const closeModal = () => {
    setModalButton(false);
  };

  useLayoutEffect(() => {
    const body = document.getElementsByTagName('body')
    body[0].setAttribute('class', 'fix-internalpages-without-header')

    return () => {
      body[0].removeAttribute('class', 'fix-internalpages-without-header')
    }
  })
  
  let SEO = ''

  if (career.pageMetadata !== null) {
    const { description, keywords, pageTitle } = career.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  }

  // Configuramos el boton segun las opciones del componente Button
  const buttonLink = {
    iconButton: {
      code: "fa-graduation-cap",
      type: "fas"
    },
    button: {
      label: "INSCRIBITE AHORA",
      url: career.inscriptionLink
    },
    customButton: {
      shape: "rectangle",
      size: "big",
      variant: "primary"
    }
  }

  const buttonLinkDistance = {
    iconButton: {
      code: "fa-graduation-cap",
      type: "fas"
    },
    button: {
      label: "INSCRIBITE AHORA",
      url: career.inscriptionLinkDistance
    },
    customButton: {
      shape: "rectangle",
      size: "big",
      variant: "primary"
    }
  }

  return (
    <Layout page={page.organizational_unit} scriptSelect={career?.scripts}>
      {SEO}
      <section>
        {career.header?.visible && (
          <div className="d-flex flex-column header-carousel position-relative">
            <Carousel carousel={career.header} keyName={`CareerPage-${career.strapiId}`} />
           
            <div className='career-location-container'>
              {career.ucc_career_places && 
                career.ucc_career_places
                  ?.sort((a, b) => a.title.localeCompare(b.title))
                  .map((places) => {
                    return (
                      <div className='d-flex align-items-center justify-content-start'>
                        <FontAwesomeIcon
                          icon={places.title === "A Distancia" ? faLaptop : faLocationDot}
                          className="mr-2"
                          color={places.colorHex}
                        />
                        <h5 className='font-italic m-0' style={{color: places.colorHex}}>{places.title}</h5>
                      </div>
                    )
                  })
                }
            </div>
          </div>
        )}

        {career.inscriptionLink && career.inscriptionLinkDistance ? (
          <div className='my-5 d-flex justify-content-center'>
            <button
              className='btn-info border-ucc-boton btn-lg d-flex '
              onClick={() => openModal()}
            >
              <Icon style={{ marginRight: '.5em' }} {...{code: "fa-graduation-cap", type: "fas"}}/>
              INSCRIBITE AHORA
            </button>
          </div>
        ) : (
          (career.inscriptionLink ?
            <Button
              icon={buttonLink.iconButton}
              button={buttonLink.button}
              customButton={buttonLink.customButton}
              key={`buttonId-${career.strapiId}`}
            ></Button>
            :
            career.inscriptionLinkDistance ?
              <Button
                icon={buttonLinkDistance.iconButton}
                button={buttonLinkDistance.button}
                customButton={buttonLinkDistance.customButton}
                key={`buttonId-${career.strapiId}`}
              ></Button>
              : 
              null
          )
        )}
        {modalButton && ( <ModalButtonAdmission career={career} onClose={closeModal}></ModalButtonAdmission>)}

        <div>
          <DynamicZone components={components} />
        </div>
      </section>
    </Layout>
  )
}

export default CareerPage

CareerPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export const careerPageQuery = graphql`
  query($careerId: Int) {
    strapiUccCareer(strapiId: { eq: $careerId }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      strapiId
      slug
      siuccCode
      inscriptionLink
      inscriptionLinkDistance
      name
      scripts {
        source
        id
        enable
        name
      }
      career_category {
        id
        name
        career_level
      }
      organizational_unit {
        name
        id
        slug
      }
      pages {
        id
        name
        slug
      }
      ucc_career_places {
        id
        title
        colorHex
      }
      header {
        visible
        slide {
          content
          imageDescription
          textButton
          name
          url
          visible

          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          diary {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
      components {
        title
        visible
        strapi_component
        id
        rightText
        leftText
        formTitle
        text
        carouselCard {
          color
          description
          labelBlack
        }
        icons {
          alt
          content
          icon {
            code
            type
            name
          }
        }
        accordion {
          title
          summary
          id
        }
        iconTexts {
          id
          alt
          content
          icon {
            code
            type
          }
        }
        textContent {
          id
          title
          visible
          variant
          backgroundImage{
            url
          }
          content
        }
        videoBack {
          id
          description
          videoUrl
          buttonVideo {
            url
            content
            page {
              slug
            }
          }
          backgroundImage {
            url
          }
          video {
            url
          }
        }
        tabs {
          id
          title
          content
          itemsAccordion {
            id
            title
            content
          }
        }
        singleImage {
          id
          title
          url
          visible
          image {
            name
            url
          }
        }
        video {
          id
          visible
          title
          description
          videoId
          mediaVideo {
            url
          }
        }
        map {
          id
          title
          visible
          code
        }
        MapContent {
          id
          code
          title
          visible
          mapContentText {
            id
            address
            contact
            openingHours
            image {
              url
            }
          }
        }
        pdf {
          id
          title
          visible
          code
        }
        audioList {
          id
          code
          title
          visible
        }
        calendar {
          id
          calendarUrl
          code {
            code
            title
            visible
          }
        }
        brick {
          id
          activateVideo
          content
          visible
          videoId
          imageName
          image {
            url
          }
        }
        eventualitiesList {
          id
          destacado
          description
          title
          visible
          url
          name
          image {
            url
          }
          eventuality {
            name
            slug
            url
            visible
            title
          }
        }
        transitionContent {
          text
          imagePosition
          image {
            url
          }
          color
        }
        heroContent {
          id
          title
          summary
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        diagonalContent {
          id
          title
          variant
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        BannerListContent {
          id
          title
          Card {
            id
            title
            description
            url
            icon {
              url
            }
            page {
              name
              slug
            }
            organizational_unit {
              name
              slug
            }
          }
        }
        quoteContent {
          title
          description
          id
          variant
          videoUrl
          image {
            name
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
          professional {
            name
            profilePicture {
              url
            }
          }
        }
        logoSection {
          title
          summary
          media {
            name
            img {
              url
            }
            imageDark {
              url
            }
          }
        }
        cards {
          cardName
          content
          destacado
          id
          type
          url
          visible
          color
          labelBlack
          backgroundImageName
          backgroundImage {
            url
            name
          }
          page {
            slug
          }
          career_level {
            name
          }
          organizational_unit {
            slug
          }
        }
        slide {
          id
          name
          content
          imageDescription
          textButton
          url
          visible
          imageFilter
          backgroundImage {
            name
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        socialNetworks {
          title
          visible
          facebook {
            code
            visible
          }
          instagram {
            code
            visible
          }
          twitter {
            code
            visible
          }
        }
        form {
          id
        }
        interest_receiver {
          option
          receiver
        }
        interest_type {
          option
          type
        }
        icon {
          code
          type
        }
        button {
          career_level {
            name
          }
          diary {
            slug
          }
          label
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
          url
        }
        customButton {
          shape
          size
          variant
        }
        profesionals {
          id
          rol
          visible
          professional {
            id
            name
            description
            profilePictureName
            profilePicture {
              url
              name
            }
          }
        }
        careers {
          id
          ucc_careers {
            name
            slug
          }
        }
        buildingsLocationList {
          title
          visible
          buildingsLocation {
            buildingName
            url
            information
          }
        }      
      }
    }
  }
`
