import React, { useEffect } from 'react'
import '../css/_modalButtonAdmission.scss'
import { Button } from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ModalButtonAdmission = ({ career, onClose }) => {

  const buttonLink = {
    iconButton: {
      code: "fa-person",
      type: "fas"
    },
    button: {
      label: "PRESENCIAL",
      url: career.inscriptionLink
    },
    customButton: {
      shape: "rectangle",
      size: "big",
      variant: "primary"
    }
  }

  const buttonLinkDistance = {
    iconButton: {
      code: "fa-laptop",
      type: "fas"
    },
    button: {
      label: "A DISTANCIA",
      url: career.inscriptionLinkDistance
    },
    customButton: {
      shape: "rectangle",
      size: "big",
      variant: "primary"
    }
  }

  // Funcion para navegar entre las imagenes apretando las teclas flecha izquierda y flecha derecha
  const handleKeyDown = (event) => {

    // Eliminar el scroll vertical en la pagina al presionar las siguientes teclas 
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Tab' || event.key === ' ') {
      event.preventDefault();
    }

    if (event.key === "Escape") {
      onClose()
    }
  };

  // Event para verificar que tecla se esta pulsando y bloqueo del scroll
  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
    };

    // Agregar event listener para ocultar la barra de desplazamiento de la pagina al abrir el modal
    document.body.style.overflow = 'hidden';

    // Agregar event listener para deshabilitar el scroll del mouse en la pagina al abrir el modal
    document.body.addEventListener('wheel', handleWheel, { passive: false });
   
    // Agregar el event listener al presionar teclas
    document.addEventListener('keydown', handleKeyDown); 
    
    // Eliminar el event listener al desmontar el modal
    return () => {
      document.body.style.overflow = 'auto'; // Restaurar la barra de desplazamiento
      document.body.removeEventListener('wheel', handleWheel);
      document.removeEventListener('keydown', handleKeyDown); 
    };
    // Desactivando el warning que mostraba por el handleKeyDown
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [] asegura que se agregue y elimine el event listener solo una vez al montar y desmontar el componente

  return (
    <div className="modal-overlay" >
      <div className="modals">
        <div className='modal-title'>
          <FontAwesomeIcon className="modal-icon" icon={['fas', 'fa-graduation-cap']}/>
        </div>

        <h4 className='font-weight-bold text-center'>Elegí tu modalidad</h4>
        
        <div>
          <Button
            icon={buttonLink.iconButton}
            button={buttonLink.button}
            customButton={buttonLink.customButton}
            classContainer={"modal-button mt-4 mb-2 d-flex justify-content-center"}            
            key={`buttonLink-Id`}
          ></Button>
        </div>

        <div>
          <Button
            icon={buttonLinkDistance.iconButton}
            button={buttonLinkDistance.button}
            customButton={buttonLinkDistance.customButton}
            classContainer={"modal-button mt-3 d-flex justify-content-center"}            
            key={`buttonLinkDistance-Id`}
          ></Button>
        </div>

        <button className='close-button' onClick={onClose}>X</button>
      </div>

    </div>
  )
}

export default ModalButtonAdmission